@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap');

@layer base {
  :root {
    --color-pink-dark: 224 27 255;
    --color-pink-light: 255 33 246;
    --color-blue-dark: 0 106 249;
    --color-blue-light: 0 192 249;
    --color-black-light: 17 17 17;
    --color-black-dark: 0 0 0;
    --color-black-lighter: 51 51 51;
    --color-gray-dark: 84 84 84;
    --color-gray-light: 163 157 157;
    --color-gray-lighter: 226 226 226;
    --color-base-background: 252 252 252;
    --color-error: 253 52 52;
    --color-success: 52 200 0;
    --color-danger: 255 70 70;
    --profile-bg: url('/profile-bg.svg');
    --color-lightertwo: 182 182 210;
  }

  [data-theme='renter'] {
    --color-primary-dark: 0 106 249;
    --color-primary-light: 0 192 249;
    --color-secondary-dark: 224 27 255;
    --color-secondary-light: 255 33 246;
    --login-sidebar-bg: url('/sidebar-gradient-renter.svg');
  }

  [data-theme='agent'] {
    --color-primary-dark: 224 27 255;
    --color-primary-light: 255 33 246;
    --color-secondary-dark: 0 106 249;
    --color-secondary-light: 0 192 249;
    --login-sidebar-bg: url('/sidebar-gradient-agent.svg');
  }
  [data-theme='landlord'] {
    --color-primary-dark: 224 27 255;
    --color-primary-light: 255 33 246;
    --color-secondary-dark: 0 106 249;
    --color-secondary-light: 0 192 249;
    --login-sidebar-bg: url('/sidebar-gradient-agent.svg');
  }
}

body {
  font-family: 'Poppins', sans-serif;
  touch-action: pan-y pan-x;
}

.font-lato {
  font-family: 'Lato', sans-serif;
}

/*Checkboxs*/
input.outlined-checkbox:checked + div {
  @apply border-pink-dark;
}

input.outlined-checkbox:checked + div svg {
  @apply block;
}

/*MapBox specific*/
.mapboxgl-map .mapboxgl-popup-content {
  @apply p-1 rounded-xl max-w-[360px] max-h-80 overflow-auto;
}

/* Slider */
.swiper.customSwiper {
  @apply overflow-visible;
}

.swiper.customSwiper .swiper-button-prev,
.swiper.customSwiper .swiper-button-next {
  @apply bg-white h-6 w-6 rounded-full;
}

.swiper.customSwiper .swiper-button-prev:after,
.swiper.customSwiper .swiper-button-next:after {
  @apply text-black-light text-xs font-bold;
}

.swiper.customSwiper .swiper-pagination-bullets.swiper-pagination-horizontal {
  @apply top-full;
}

.customSwiper .swiper-wrapper {
  @apply rounded-lg;
}

.customSwiper .swiper-pagination-bullet {
  @apply w-1 h-1 opacity-100 bg-blue-dark/20;
}

.customSwiper .swiper-pagination-bullet.swiper-pagination-bullet-active {
  @apply w-3.5 rounded-2xl bg-blue-dark;
}

/* Login Slider */
.swiper.loginSlider .swiper-pagination-bullet {
  @apply w-3 h-3 opacity-100 bg-white/20;
}
.swiper.loginSlider .swiper-pagination-bullet.swiper-pagination-bullet-active {
  @apply bg-white;
}
.swiper.loginSlider .swiper-pagination-bullets.swiper-pagination-horizontal {
  @apply bottom-0;
}
.swiper.loginSlider .swiper-wrapper {
  @apply mb-10;
}

/* Scrollbar*/
.scrollbar-container.ps .ps__thumb-y,
.scrollbar-container.ps .ps__rail-y:hover > .ps__thumb-y,
.scrollbar-container.ps .ps__rail-y:focus > .ps__thumb-y,
.scrollbar-container.ps .ps__rail-y.ps--clicking .ps__thumb-y,
.scrollbar-container.ps .ps__thumb-x,
.scrollbar-container.ps .ps__rail-x:hover > .ps__thumb-x,
.scrollbar-container.ps .ps__rail-x:focus > .ps__thumb-x,
.scrollbar-container.ps .ps__rail-x.ps--clicking .ps__thumb-x {
  @apply bg-blue-dark;
}

.scrollbar-container.ps .ps__rail-y:hover > .ps__thumb-y,
.scrollbar-container.ps .ps__rail-x:hover > .ps__thumb-x {
  @apply bg-blue-dark;
}

.scrollbar-container.ps .ps__rail-x:hover,
.scrollbar-container.ps .ps__rail-y:hover,
.scrollbar-container.ps .ps__rail-x:focus,
.scrollbar-container.ps .ps__rail-y:focus,
.scrollbar-container.ps .ps__rail-x.ps--clicking,
.scrollbar-container.ps .ps__rail-y.ps--clicking {
  @apply bg-blue-dark/20;
}

.scrollbar-container.ps:hover > .ps__rail-x,
.scrollbar-container.ps:hover > .ps__rail-y,
.scrollbar-container.ps .ps--focus > .ps__rail-x,
.scrollbar-container.ps .ps--focus > .ps__rail-y,
.scrollbar-container.ps .ps--scrolling-x > .ps__rail-x,
.scrollbar-container.ps .ps--scrolling-y > .ps__rail-y {
  @apply opacity-100;
}

.scrollbar-container.ps.ps--active-y .ps__rail-y {
  left: auto !important;
}

.scrollbar-container.ps.ps--active-x .ps__rail-x {
  top: auto !important;
}

.zeme-scroll-wrap > div::-webkit-scrollbar {
  width: 6px;
}

.zeme-scroll-wrap > div::-webkit-scrollbar-thumb {
  @apply bg-blue-dark rounded-2xl;
}

.zeme-scroll-wrap > div::-webkit-scrollbar-track {
  @apply bg-blue-dark/20 rounded-2xl;
}

/* Input numbers*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  @apply appearance-none;
}

/* Select autocomplete */
.zm-select-wrap .zm-select__control {
  @apply rounded-lg border border-lightertwo/50 hover:border-lightertwo/50 text-sm font-normal;
}
.zm-select-wrap .zm-select__control.zm-select__control--is-focused {
  @apply ring-blue-dark/70 ring-2 border-transparent bg-blue-dark/5;
}
.zm-select-wrap .zm-select__value-container .zm-select__placeholder {
  @apply text-gray-dark/40 truncate text-sm font-normal;
}
/* .zm-select-wrap .zm-select__control.zm-select__control--menu-is-open {
  @apply rounded-b-none;
} */
.zm-select-wrap .zm-select__value-container {
  @apply py-2 px-4 font-medium cursor-text;
}
.zm-select-wrap .zm-select__input-container::after {
  max-width: 2px;
}
.zm-select-wrap .zm-select__indicators .zm-select__dropdown-indicator,
.zm-select-wrap .zm-select__indicators .zm-select__indicator-separator {
  @apply hidden;
}
.zm-select-wrap .zm-select__indicators .zm-select__clear-indicator {
  @apply cursor-pointer py-3 px-4;
}
.zm-select-wrap .zm-select__menu {
  @apply m-0 border-t shadow-container rounded-t-none mt-0.5;
  border-image: linear-gradient(
      to right,
      white,
      rgb(var(--color-blue-dark)),
      white
    )
    1 0 0 0;
}
.zm-select-wrap .zm-select__option {
  @apply text-base text-black-dark py-3 px-5 cursor-pointer mx-0 mt-px;
}
.zm-select-wrap .zm-select__option.zm-select__option--is-focused,
.zm-select-wrap .zm-select__option.zm-select__option--is-selected {
  @apply bg-blue-dark/10 text-blue-dark;
}
.zm-select-wrap .zm-select__input:focus {
  @apply ring-0 ring-transparent shadow-none outline-none;
}
.zm-select-wrap.zm-address-select {
  @apply w-full;
}

/* Address auto complete */
.zm-select-wrap.zm-address-select .zm-select__control {
  @apply border border-gray-lighter rounded-full;
}
.zm-select-wrap.zm-address-select
  .zm-select__control.zm-select__control--is-focused {
  @apply border-blue-dark/70;
}
.zm-select-wrap.zm-address-select .zm-select__value-container {
  @apply py-2 px-5 sm:pl-12 font-medium cursor-text flex-nowrap;
}
.zm-select-wrap.zm-address-select
  .zm-select__value-container
  .zm-select__placeholder {
  @apply text-gray-dark/40 truncate;
}
.zm-select-wrap.zm-address-select
  .zm-select__indicators
  .zm-select__loading-indicator {
  @apply mr-2;
}
.zm-select-wrap.zm-select-wrap .zm-select__menu {
  @apply border-none;
}
.zm-select-wrap.zm-address-select.landing-search .zm-select__control {
  @apply h-16;
}
.zm-select-wrap.zm-address-select.landing-search
  .zm-select__value-container
  .zm-select__placeholder {
  @apply text-lg;
}
.zm-select-wrap.zm-address-select.landing-search
  .zm-select__value-container
  .zm-select__input-container {
  @apply text-lg;
}

/* Map marker */
.mapbox-marker {
  @apply h-8 w-8 bg-contain bg-no-repeat bg-center;
}

.mapbox-marker.marker-standard {
  background-image: url('../../public/assets/icons/blue_standard_marker.svg');
  height: 1.5rem;
  width: 1.5rem;
}

.mapbox-marker.marker-saved {
  background-image: url('../../public/assets/icons/pink_standard_marker.svg');
  height: 1.5rem;
  width: 1.5rem;
}

.mapbox-marker.marker-selected {
  background-image: url('../../public/assets/icons/marker_selected.svg');
  @apply h-8 w-8 bg-contain bg-no-repeat bg-center;
  height: 1.5rem;
  width: 1.5rem;
}

.border-gradient {
  @apply border-primary-dark;
  border-image: linear-gradient(to right, white, rgba(0, 106, 249, 0.5), white)
    1 0 0 0;
}

/* datepicker  */
.zeme-datepicker-container-wrap .react-datepicker-wrapper {
  @apply w-full;
}

.zeme-datepicker-container-wrap .zeme-date-selector-wrap {
  @apply z-50;
}

.zeme-datepicker-container-wrap .react-datepicker-wrapper .listing-input {
  @apply block w-full h-12 rounded-xl px-4 py-1.5 text-black-light border border-gray-lighter text-sm font-medium bg-transparent;
}

.zeme-datepicker-container-wrap
  .react-datepicker-wrapper
  .listing-input:placeholder {
  @apply text-black-dark/40 font-normal;
}

.zeme-datepicker-container-wrap .react-datepicker-wrapper .listing-input:focus {
  @apply ring-0 ring-transparent shadow-none border-pink-dark;
}
.zeme-date-selector-wrap.react-datepicker-popper[data-placement^='top']
  .react-datepicker__triangle,
.zeme-date-selector-wrap.react-datepicker-popper[data-placement^='bottom']
  .react-datepicker__triangle {
  transform: translate(100px, 0) !important;
}

.zeme-date-selector-wrap.react-datepicker-popper[data-placement^='top']
  .react-datepicker__triangle:before,
.zeme-date-selector-wrap.react-datepicker-popper[data-placement^='bottom']
  .react-datepicker__triangle:before {
  @apply border-none;
}

.zeme-date-selector-wrap.react-datepicker-popper[data-placement^='bottom']
  .react-datepicker__triangle:after {
  @apply border-b-white;
}

.zeme-date-selector-wrap .zeme-date-selector {
  @apply border-none drop-shadow-container text-sm rounded-lg;
}

.zeme-date-selector .react-datepicker__header {
  @apply bg-white border-none;
}

.zeme-date-selector .react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  @apply pt-2 pb-3 relative;
}

.zeme-date-selector .react-datepicker__navigation {
  @apply top-[10px];
}

.zeme-date-selector .react-datepicker__year-select {
  @apply border border-gray-lighter/80 rounded-md text-sm py-1;
  visibility: visible !important;
}

.zeme-date-selector .react-datepicker__year-select:focus {
  outline: none;
  @apply ring-transparent;
}

.zeme-date-selector .react-datepicker__header .react-datepicker__day-names {
  @apply pt-2;
}

.zeme-date-selector .react-datepicker__header .react-datepicker__day-name {
  @apply w-8 leading-8 font-semibold;
}

.zeme-date-selector .zeme-day-picker {
  @apply w-8 leading-8;
}

.zeme-day-picker.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  @apply bg-transparent;
}

.zeme-day-picker.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  @apply bg-primary-dark text-white rounded-lg outline-none;
}

.zeme-day-picker.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
  @apply bg-primary-dark text-white rounded-lg;
}

/* Tostify messages  */
.Toastify__toast-container .Toastify__toast {
  @apply rounded-2xl;
}

.Toastify__toast-container .Toastify__toast-body {
  @apply text-sm;
}

/* Tostify Info  */
.Toastify__toast-theme--light.Toastify__toast--info {
  @apply bg-[#ecf1ff] text-[#384c82];
}

.Toastify__toast-container
  .Toastify__toast-theme--light.Toastify__toast--info
  .Toastify__toast-icon
  svg {
  @apply fill-[#4779ff];
}

/* Tostify Success  */
.Toastify__toast-theme--light.Toastify__toast--success {
  @apply bg-[#e9faf2] text-[#2b6f4e];
}

.Toastify__toast-container
  .Toastify__toast-theme--light.Toastify__toast--success
  .Toastify__toast-icon
  svg {
  @apply fill-[#26d07c];
}

/* Tostify Warning  */
.Toastify__toast-theme--light.Toastify__toast--warning {
  @apply bg-[#fdf0e5] text-[#7b471d];
}

.Toastify__toast-container
  .Toastify__toast-theme--light.Toastify__toast--warning
  .Toastify__toast-icon
  svg {
  @apply fill-[#ed6c02];
}

/* Tostify Error  */
.Toastify__toast-theme--light.Toastify__toast--error {
  @apply bg-[#fee5e8] text-[#7c1c28];
}

.Toastify__toast-container
  .Toastify__toast-theme--light.Toastify__toast--error
  .Toastify__toast-icon
  svg {
  @apply fill-[#F1001F];
}

.field iframe {
  @apply h-[52px] w-full border-none;
}

.landing-blue-bg {
  background-image: url('../../public/assets/landing-page/blue-bg.svg');
}

.banner-overlay {
  background-image: url('../../public/assets/landing-page/banner-overlay.svg');
}

.bg-black-overlay {
  background-image: url('../../public/assets/landing-page/black-bg.svg');
}

.blue-side-layer {
  background-image: url('../../public/assets/landing-page/side-layer.svg');
}

.landing-pink-bg {
  background-image: url('../../public/assets/landing-page/pink-bg.svg');
}

.pink-side-layer {
  background-image: url('../../public/assets/landing-page/pink-side-layer.svg');
}
.marker-label {
  @apply bg-white block py-0.5 px-1.5 rounded-lg border border-blue-dark shadow-container text-xs font-medium text-blue-dark min-w-[50px] text-center after:content-[''] after:absolute after:border-[6px] after:border-blue-dark after:border-l-transparent after:border-r-transparent after:border-b-0 after:w-0 after:h-0 after:bottom-[-6px] after:left-[50%] after:translate-x-[-50%] before:content-[''] before:absolute before:border-[6px] before:border-white before:border-l-transparent before:border-r-transparent before:border-b-0 before:w-0 before:h-0 before:bottom-[-5px] before:left-[50%] before:translate-x-[-50%] before:z-10 cursor-pointer hover:!opacity-80;
}
.marker-active-label {
  @apply text-pink-dark border-pink-dark after:border-pink-dark after:border-l-transparent after:border-r-transparent;
}

/* Page Loader */
.pageloader {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite;
}
.pageloader::before,
.pageloader::after {
  content: '';
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 6px solid #ccf2fe;
  animation: prixClipFix 2s linear infinite;
}
.tu-loader.pageloader::after {
  border-color: #3aa7cc;
}
.pageloader::after {
  transform: rotate3d(90, 90, 0, 180deg);
  border-color: #006af9;
}
.horizontal-slider.disabled {
  opacity: 0.5;
  cursor: not-allowed !important;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes prixClipFix {
  0% {
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
  }
  50% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
  }
  75%,
  100% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
  }
}

.progress-bar-value {
  animation: indeterminateAnimation 2s infinite linear;
  transform-origin: 0% 50%;
}

@keyframes indeterminateAnimation {
  0% {
    transform: translateX(0) scaleX(0);
  }
  40% {
    transform: translateX(0) scaleX(0.4);
  }
  100% {
    transform: translateX(100%) scaleX(0.5);
  }
}

.yarl__slide.yarl__slide_current img {
  max-width: 100% !important;
  max-height: 100% !important;
  height: 100%;
  width: auto;
}
.rpv-toolbar__right {
  display: none !important;
}
.placeholder {
  position: absolute;
  background-color: white;
  border-radius: 3px;
  border: dashed 1px blue;
  background-color: white;
}
.apple-pay-button-with-text {
  display: none;
}

/* CSS */
.apple-pay-button {
  display: inline-block;
  -webkit-appearance: -apple-pay-button;
  -apple-pay-button-type: plain; /* Use any supported button type. */
  width: 240px;
  height: 40px;
  cursor: pointer;
}
.apple-pay-button:hover {
  opacity: 0.7;
}
.apple-pay-button-black {
  -apple-pay-button-style: black;
}
.apple-pay-button-white {
  -apple-pay-button-style: white;
}
.apple-pay-button-white-with-line {
  -apple-pay-button-style: white-outline;
}

/* Typing loader animation */
@keyframes blink {
  50% {
    fill: transparent;
  }
}
.dot {
  animation: 1s blink infinite;
  fill: grey;
}
.dot:nth-child(2) {
  animation-delay: 250ms;
}
.dot:nth-child(3) {
  animation-delay: 500ms;
}
.zm-select__value-container.zm-select__value-container--is-multi {
  @apply overflow-visible;
}
.zm-select__control.zm-select__control--is-focused
  .zm-select__value-container.zm-select__value-container--is-multi {
  @apply overflow-hidden;
}
.zm-select__multi-value__label {
  @apply max-w-[150px];
}
.zm-select__value-container .zm-select__multi-value {
  @apply bg-primary-dark/20;
}
.zm-select__input-container {
  display: flex !important;
}
.zm-select__input-container input.zm-select__input {
  width: 100%;
}

.rotate-90 {
  @apply -rotate-[90deg];
}
.rotate-180 {
  @apply -rotate-[180deg];
}
.rotate-270 {
  @apply -rotate-[270deg];
}
.rotate-360 {
  @apply -rotate-[360deg];
}

.pricing-slider .swiper-wrapper {
  @apply sm:items-center sm:justify-center min-h-[460px];
}

html {
  height: calc(100vh - env(safe-area-inset-bottom));
}
